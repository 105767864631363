import React, { useRef, useState } from "react"
import Layout from "../components/layout"
import PageHeader from "../components/header/page-header"
import { useStaticQuery, graphql } from "gatsby"
import PrimaryButton from "../components/buttons/primary_button"
import SEO from "../components/seo"

import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"

import { useForm } from "react-hook-form"
import { Colors } from "../config/config"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query Contact {
      bgImage: file(relativePath: { eq: "contact/contact-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [loading, setLoading] = useState(false)
  const [succesMsg, setSuccessMsg] = useState(null)
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = useForm()

  const onSubmit = async values => {
    setLoading(true)
    let today = {
      createdAt: new Date(),
    }
    let data = { ...values, ...today }
    console.log(data)
    sendEmail(data)
      .then(() => {
        setLoading(false)
        window.scrollTo(0, 0)
        reset()
        setSuccessMsg(
          <span style={{ fontSize: 20 }}>
            Email Successfully Sent to{" "}
            <b style={{ color: Colors.ACCENT_COLOR }}>hello@explorelogy.lk</b>
          </span>
        )
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  const sendEmail = data =>
    new Promise((resolve, reject) => {
      const axiosConfig = {
        method: "post",
        url: process.env.GATSBY_SEND_MAIL_URL,
        data: data,
      }
      axios(axiosConfig)
        .then(() => {
          resolve(true)
        })
        .catch(err => {
          reject(err)
        })
    })

  const successHandle = () => {
    if (loading) {
      return (
        <div className="d-flex justify-content-center">
          <div>
            <div
              className="spinner-grow text-danger"
              style={{ width: 40, height: 40 }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )
    } else if (succesMsg) {
      return <div className="d-flex justify-content-center">{succesMsg}</div>
    }
  }

  const headerBg = data.bgImage.childImageSharp.fluid
  return (
    <>
      <Layout bgColor="#f4f4f9">
        <SEO
          title="Contact | Get started right away"
          description="Get in touch with us, we can help you streamline your business with Explorelogy services."
        />
        <PageHeader
          background={headerBg}
          title="Let’s Connect"
          subtitle="Get in touch with us, we can help you streamline your business with Explorelogy services."
          thisPage="Contact Us"
        >
          <div style={{ height: 80 }}> </div>
        </PageHeader>
        <div style={{ paddingBottom: 80 }} className="container">
          <div className="col-md-10 mx-auto">
            <form onSubmit={handleSubmit(onSubmit)} className="contact-us-form">
              <ReCAPTCHA
                sitekey="6LdeNM8ZAAAAAMV24Q1zv6X5boSFMzHjz3_b0Ici"
                size="invisible"
                theme="dark"
              />

              {successHandle()}

              {!loading && !succesMsg ? (
                <div>
                  <div className="row">
                    <div className="col-md-6 pb-4">
                      <label>
                        Full Name*
                        <span>
                          <input
                            {...register("name", {
                              required: true,
                            })}
                            placeholder="e.g., John Doe"
                            type="text"
                            name="name"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6 pb-4">
                      <label>
                        Organization
                        <span>
                          <input
                            {...register("companyName")}
                            placeholder="Company Name"
                            type="text"
                            name="companyName"
                          />
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6 pb-4">
                      <label>
                        Work Email Address*
                        <span>
                          <input
                            style={
                              errors.email && errors.email.message
                                ? { border: `1px solid ${Colors.ACCENT_COLOR}` }
                                : {
                                    border: `1px solid ${Colors.SUCCESS_COLOR}`,
                                  }
                            }
                            name="email"
                            placeholder="name@company.com"
                            {...register("email", {
                              required: "Required",
                              pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                          />
                          <span
                            style={{
                              fontSize: 13,
                              fontStyle: "italic",
                              color: "red",
                            }}
                          >
                            {errors.email && errors.email.message}
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6 pb-4">
                      <label>
                        Phone Number*
                        <span>
                          <input
                            style={
                              errors.phoneNumber && errors.phoneNumber.message
                                ? { border: `1px solid ${Colors.ACCENT_COLOR}` }
                                : {
                                    border: `1px solid ${Colors.SUCCESS_COLOR}`,
                                  }
                            }
                            type="tel"
                            name="phoneNumber"
                            placeholder="ex: 0111234567"
                            {...register("phoneNumber", {
                              required: "Required",

                              pattern: {
                                value: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                message: "Invalid Mobile Number",
                              },
                            })}
                          />
                          <span
                            style={{
                              fontSize: 13,
                              fontStyle: "italic",
                              color: "red",
                            }}
                          >
                            {errors.phoneNumber && errors.phoneNumber.message}
                          </span>
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6 pb-4">
                      <label>
                        Company Size*
                        <span>
                          <select
                            {...register("companySize")}
                            name="companySize"
                            id="company-size"
                          >
                            <option value="Please Select">Please Select</option>
                            <option value="Startup Level">Startup Level</option>
                            <option value="10-50">10-50</option>
                            <option value="50-500">50-500</option>
                            <option value="500-1500">500-1500</option>
                            <option value="1500+">1500+</option>
                          </select>
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6 pb-4">
                      <label>
                        What is your inquiry about? *
                        <span>
                          <select
                            {...register("about")}
                            name="about"
                            id="about-inquiry"
                            required
                          >
                            <optgroup label="--- Services ---">
                              <option value="Please Select">
                                Please Select
                              </option>
                              <option value="Business Concept Development">
                                Business Concept Development
                              </option>
                              <option value="Web Development">
                                Web Development
                              </option>
                              <option value="Cyber Security">
                                Cyber Security
                              </option>
                              <option value="Managed Cloud Service">
                                Managed Cloud Service
                              </option>
                              <option value="Managed IT Service">
                                Managed IT Service
                              </option>
                              <option value="IT Consultancy">
                                IT Consultancy
                              </option>
                            </optgroup>
                            <optgroup label="--- Products ---">
                              <option value="Eduplus">Eduplus</option>
                              <option value="Revision LIVE">
                                Revision LIVE
                              </option>
                              <option value="YELA ERP">YELA ERP</option>
                              <option value="Laura Hotel System">
                                Laura Hotel System
                              </option>
                            </optgroup>
                          </select>
                        </span>
                      </label>
                    </div>
                    <div className="col-md-12 pb-4">
                      <label>
                        How we can help you? *
                        <span>
                          <textarea
                            placeholder="Let us know what you need!"
                            name="message"
                            id="contact-message"
                            {...register("message", {
                              required: true,
                            })}
                            cols="10"
                          ></textarea>
                        </span>
                      </label>
                    </div>
                    <div style={{ height: 20 }}></div>

                    <PrimaryButton
                      submit
                      title="SUBMIT"
                      style={{ width: "100%", textAlign: "center" }}
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </form>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Contact
